import * as THREE from 'three'
import * as dat from 'lil-gui'
import * as CANNON from 'cannon-es'

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js"

THREE.ColorManagement.enabled = false

/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// implement gui only when the url has a #debug
if (window.location.hash === '#debug') {
    gui.show()
} else {
    gui.hide()
}


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/8.png')
const matcapTextureForText = textureLoader.load('/textures/matcaps/7.png')

const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/0/px.png',
    '/textures/environmentMaps/0/nx.png',
    '/textures/environmentMaps/0/py.png',
    '/textures/environmentMaps/0/ny.png',
    '/textures/environmentMaps/0/pz.png',
    '/textures/environmentMaps/0/nz.png'
])

/**
 * Physics
 */
// World
const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = true
world.gravity.set(0, - 9.82, 0)

// Floor
const floorShape = new CANNON.Plane()
const floorBody = new CANNON.Body()
floorBody.mass = 0
floorBody.addShape(floorShape)
floorBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(- 1, 0, 0),
    Math.PI * 0.5
)
floorBody.position.y = - 10
world.addBody(floorBody)

// Materials
const defaultMaterial = new CANNON.Material('default')

const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: 0.1,
        restitution: 0.7
    }
)
world.addContactMaterial(defaultContactMaterial)
world.defaultContactMaterial = defaultContactMaterial

/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(25, 15),
    new THREE.MeshStandardMaterial({
        color: '#000000',
        metalness: 0.3,
        roughness: 0.4,
        envMap: environmentMapTexture,
        envMapIntensity: 0.5
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
floor.position.set(0, - 11, 0)
scene.add(floor)


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 0.7)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 0.2)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = -10
directionalLight.shadow.camera.left = -15
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 15
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 0)
scene.add(directionalLight)


/**
 * Fonts
 */
// object to update
const objectsToUpdate = []

const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            'Awesome Lab',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )

        textGeometry.center()

        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
        const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTextureForText })
        const text = new THREE.Mesh(textGeometry, textMaterial)
        scene.add(text)

        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
        const CapsuleGeometry = new THREE.CapsuleGeometry(0.3, 0.2, 20, 45)
        const BoxGeometry = new THREE.BoxGeometry(0.5, 0.5, 0.5, 20, 45)
        const sphereGeometry = new THREE.SphereGeometry(0.3)

        const boxshapePhisics = new CANNON.Box(new CANNON.Vec3(BoxGeometry.parameters.width / 2, BoxGeometry.parameters.height / 2, BoxGeometry.parameters.depth / 2));
        const spherePhisics = new CANNON.Sphere(sphereGeometry.parameters.radius)
        const torusPhisics = new CANNON.Cylinder(donutGeometry.parameters.radius, donutGeometry.parameters.radius, donutGeometry.parameters.tube, 20)
        const CapsulePhisics = new CANNON.Cylinder(CapsuleGeometry.parameters.radiusTop, CapsuleGeometry.parameters.radiusBottom, CapsuleGeometry.parameters.height, 20)

        for(let i = 0; i < 300; i++) {
            // three js mesh
            const shapeGeometry = Math.random() < 0.25 ? donutGeometry : Math.random() < 0.5 ? CapsuleGeometry : Math.random() < 0.75 ? BoxGeometry : sphereGeometry

            const shape = new THREE.Mesh(shapeGeometry, material)

            shape.position.x = (Math.random() - 0.5) * 20
            shape.position.y = (Math.random() - 0.5) * 10
            shape.position.z = (Math.random() - 0.5) * 10

            shape.rotation.x = Math.random() * Math.PI
            shape.rotation.y = Math.random() * Math.PI

            const scale = Math.random()
            shape.scale.set(scale, scale, scale)

            shape.castShadow = true

            scene.add(shape)

            let shapePhisics = null

            switch (shapeGeometry.type) {
                case 'BoxGeometry':
                    shapePhisics = boxshapePhisics
                    break;
                case 'SphereGeometry':
                    shapePhisics = spherePhisics
                    break;
                case 'TorusGeometry':
                    shapePhisics = torusPhisics
                    break;
                case 'CapsuleGeometry':
                    shapePhisics = CapsulePhisics
                    break;
                default:
                    break;
            }

            // cannon js body
            const shapeBody = new CANNON.Body({
                mass: 1,
                shape: shapePhisics,
                material: defaultMaterial,
                size: [scale, scale, scale],
                position: new CANNON.Vec3(shape.position.x, shape.position.y, shape.position.z)
            })

            shapeBody.position.copy(shape.position)
            shapeBody.quaternion.copy(shape.quaternion)

            world.addBody(shapeBody)

            // save in objects to update
            objectsToUpdate.push({
                mesh: shape,
                body: shapeBody
            })
        }
    }
)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -6
camera.position.y = -4
camera.position.z = 20
scene.add(camera)

// debuf camera
gui.add(camera.position, 'x').min(-20).max(20).step(0.01)
gui.add(camera.position, 'y').min(-20).max(20).step(0.01)
gui.add(camera.position, 'z').min(-20).max(20).step(0.01)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
// limit scroll
controls.minDistance = 4
controls.maxDistance = 20

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

let MouseIsClicked = false
window.addEventListener('dblclick', () => {
    MouseIsClicked = true
})


let touchtime = 0
window.addEventListener('touchstart', () => {
    if (touchtime === 0) {
        // set first click
        touchtime = new Date().getTime()
    } else {
        // compare first click to this click and see if they occurred within double click threshold
        if (((new Date().getTime()) - touchtime) < 800) {
            // double click occurred
            MouseIsClicked = true
            touchtime = 0
        } else {
            // not a double click so set as a new first click
            touchtime = new Date().getTime()
        }
    }
})


/**
 * Animate
 */
const clock = new THREE.Clock()
let oldElapsedTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - oldElapsedTime
    oldElapsedTime = elapsedTime

    if (MouseIsClicked) {
        // Update physics world
        world.step(1 / 60, deltaTime, 3)
        for (const object of objectsToUpdate) {
            object.mesh.position.copy(object.body.position)
            object.mesh.quaternion.copy(object.body.quaternion)
    }
}

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()